.count {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: bold;
}

.navigation {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
