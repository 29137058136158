.companyInfo {
    display: inline-block;
    padding: {
        left: 10px;
        bottom: 3px;
    }
    align-self: center;

}

.company-wrapper {
    display: flex;
}

.company-wrapper > * {
    align-self: center;
}

.company-wrapper > h5 {
    padding-left: 10px;
    padding-right: 10px;
}